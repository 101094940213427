import { Box, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import ArrowRight from 'src/components/icons/ArrowRight'
import theme, { COLOR } from 'src/styles/theme'
import { makeStyles } from 'tss-react/mui'
import sanitize from 'src/common/utils/js/sanitize'
import { rem } from 'src/common/utils/css'
import { useLinkClickHandler } from 'src/common/utils/hooks/useLinkClickHandler'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import ExternalLink from 'src/components/icons/ExternalLink'

const color = {
  CYPRESS_GREEN: 'cypress_green',
  BAKER_BEACH: 'the_baker_beach',
}

const useStyles = makeStyles()((defaultTheme, props) => ({
  card: {
    padding: `${rem(16)} ${rem(24)}`,
    width: '100%',
    backgroundColor:
      props.color === color.CYPRESS_GREEN
        ? theme.palette.primary.dark
        : theme.palette.presidio.color.BAKER_BEACH_WHITE,
    cursor: 'pointer',
    '&: active': {
      backgroundColor:
        props.color === color.CYPRESS_GREEN
          ? theme.palette.secondary.dark
          : theme.palette.presidio.color.LIGHT_BACKGROUND,
    },
  },
  link: {
    ...theme.typography.h3,
    color:
      props.color === color.CYPRESS_GREEN
        ? theme.palette.presidio.color.NEAR_WHITE
        : theme.palette.primary.dark,
    textDecoration: props.events.isHovering ? 'underline' : 'none',
    textDecorationThickness: rem(1.8),
    textUnderlineOffset: rem(3),
    [theme.breakpoints.down('lg')]: {
      fontSize: rem(24),
    },
  },
  description: {
    ...theme.typography.body.default,
    marginTop: rem(8),
    color:
      props.color === color.CYPRESS_GREEN
        ? theme.palette.presidio.color.NEAR_WHITE
        : theme.palette.primary.dark,
  },
  icon: {
    marginTop: rem(1),
    [theme.breakpoints.up('lg')]: {
      marginTop: rem(5),
    },
  },
}))

function CardQuickLink(props) {
  const { data } = props
  if (!data) return null

  const { link, description, background_color } = data
  if (!link) return null

  const { title, url, target } = link
  if (!url) return null

  const [isHovering, setIsHovering] = React.useState(false)

  const { classes } = useStyles({
    events: { isHovering },
    color: background_color,
  })

  const handleMouseEnter = (e) => {
    setIsHovering(true)
  }

  const handleMouseLeave = (e) => {
    setIsHovering(false)
  }

  const linkClickHandler = useLinkClickHandler()

  return (
    <a
      href={url}
      aria-label={title || 'quick-link'}
      onClick={(e) => {
        e.preventDefault()
        linkClickHandler(link)
      }}
      data-ga-location="quicklink"
    >
      <Box
        className={classes.card}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Stack direction="row">
          <Stack flex={1}>
            <Typography
              variant="h3"
              className={classes.link}
              data-testid="card-quick-link-title"
            >
              {title}
            </Typography>
            <div className={classes.description}>{sanitize(description)}</div>
          </Stack>

          <Box className={classes.icon}>
            {isUrlExternal(url) ? (
              <ExternalLink
                data-testid="card-quick-link-icon"
                color={
                  background_color !== color.CYPRESS_GREEN
                    ? theme.palette.primary.dark
                    : COLOR.NEAR_WHITE
                }
              />
            ) : (
              <ArrowRight
                data-testid="card-quick-link-icon"
                color={
                  background_color !== color.CYPRESS_GREEN
                    ? theme.palette.primary.dark
                    : undefined
                }
              />
            )}
          </Box>
        </Stack>
      </Box>
    </a>
  )
}

export default CardQuickLink

CardQuickLink.propTypes = {
  data: PropTypes.shape({
    link: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string.isRequired,
      target: PropTypes.string,
    }).isRequired,
    description: PropTypes.string,
    background_color: PropTypes.string,
  }),
}
